<template>
	<div class="cats">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="cats_inner">
				<div class="header">
					<div class="title">Gatti di {{ owner_data.name }}</div>
					<div class="button" @click="openNewCatPopup()">NUOVO GATTO</div>
				</div>
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:search-options="{
						enabled: true,
						placeholder: 'Cerca nella tabella',
					}">
						<template #table-row="props">
							<span class="actions" v-if="props.column.field == 'actions'">
								<button @click="openEditCatPopup(props.row)"><i class="fa-solid fa-pen-to-square"></i></button>
								<button style="color: #d11507;" @click="openDeleteCatPopup(props.row)"><i class="fa-solid fa-trash"></i></button>
							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
						</template>
				</vue-good-table>
				<vue-final-modal v-model="edit_cat" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span v-if="edit_cat_new">NUOVO GATTO</span>
						<span v-else>MODIFICA GATTO</span>
						<button class="modal_close" @click="edit_cat = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<label>Nome</label>
							<input type="text" v-model="name" />
						</div>
						<div class="modal_content_row">
							<label>Data di nascita</label>
							<input type="date" v-model="birth_date" />
						</div>
						<div class="modal_content_row">
							<label>Sesso</label>
							<div class="modal_radio">
								<input type="radio" id="gender_m" value="M" v-model="gender" />
								<label for="gender_m">Maschio</label>
								<input type="radio" id="gender_f" value="F" v-model="gender" />
								<label for="gender_f">Femmina</label>
							</div>
						</div>
						<div class="modal_content_row">
							<label>Tipo di cibo</label>
							<input type="text" v-model="food_type" />
						</div>
						<div class="modal_content_row">
							<label>Frequenza somministrazione</label>
							<input type="text" v-model="food_frequency" />
						</div>
						<div class="modal_content_row">
							<label>Tipo cassettina igienica utilizzata</label>
							<div class="modal_radio">
								<input type="radio" id="litter_box_chiusa" value="Chiusa" v-model="litter_box" />
								<label for="litter_box_chiusa">Chiusa</label>
								<input type="radio" id="litter_box_aperta" value="Aperta" v-model="litter_box" />
								<label for="litter_box_aperta">Aperta</label>
							</div>
						</div>
						<div class="modal_content_row">
							<label>Tipo lettiera utilizzata</label>
							<div class="modal_radio">
								<input type="radio" id="litter_type_agglomerante" value="Agglomerante" v-model="litter_type" />
								<label for="aggressive_yes">Agglomerante</label>
								<input type="radio" id="litter_type_silicio" value="Silicio" v-model="litter_type" />
								<label for="aggressive_no">Silicio</label>
							</div>
						</div>
						<div class="modal_content_row">
							<label>Eventuali patologie</label>
							<input type="text" v-model="pathologies" />
						</div>
						<div class="modal_content_row">
							<label>Abitudini e carattere</label>
							<input type="text" v-model="personality" />
						</div>
						<div class="modal_content_row">
							<label>PlayRoom</label>
							<div class="modal_radio">
								<input type="radio" id="playroom_yes" value="1" v-model="playroom" />
								<label for="playroom_yes">Si</label>
								<input type="radio" id="playroom_no" value="0" v-model="playroom" />
								<label for="playroom_no">No</label>
							</div>
						</div>
						<div class="modal_content_row">
							<label>Note</label>
							<textarea v-model="notes"></textarea>
						</div>
						<div class="modal_content_row">
							<label>Proprietario</label>
							<OwnerSelect :preselected="owner" @update:preselected="setOwnerSelected"></OwnerSelect>
						</div>
						<div class="modal_content_row">
							<label>Veterinario</label>
							<VeterinarianSelect :preselected="veterinarian" @update:preselected="setVeterinarianSelected"></VeterinarianSelect>
						</div>
						<div class="modal_content_row">
							<button class="button" :class="{ disabled: !cat_fields_ok }" @click="saveCat()">SALVA</button>
						</div>
					</div>
				</vue-final-modal>
				<vue-final-modal v-model="delete_cat" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span>RIMUOVI GATTO</span>
						<button class="modal_close" @click="delete_cat = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<p class="message">Vuoi davvero rimuovere il gatto <b>{{ name }}</b>?</p>
						</div>
						<div class="modal_content_row flex_row flex_end">
							<button class="button" @click="deleteCat()">CONFERMA</button>
							<button class="button cancel" @click="delete_cat = false">ANNULLA</button>
						</div>
					</div>
				</vue-final-modal>
			</div>
			<div class="loader" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
import OwnerSelect from '@/components/OwnerSelect.vue'
import VeterinarianSelect from '@/components/VeterinarianSelect.vue'

export default {
	name: 'CatsPage',
	components: {
		TopHeader,
		OwnerSelect,
		VeterinarianSelect
	},
	data() {
		return {
			loading: true,
			owner_id: 0,
			owner_data: '',
			columns: [
				{
					label: 'Nome',
					field: 'name'
				},
				{
					label: 'Data di nascita',
					field: 'birth_date_formatted'
				},
				{
					label: 'Sesso',
					field: 'gender_text'
				},
				{
					label: 'PlayRoom',
					field: 'playroom_text'
				},
				{
					label: 'Veterinario',
					field: 'veterinarian_name'
				},
				{
					label: '',
					field: 'actions',
					sortable: false,
					width: '70px',
				},
			],
			rows: [],
			edit_cat: false,
			edit_cat_new: false,
			delete_cat: false,
			id: '',
			name: '',
			birth_date: '',
			gender: '',
			food_type: '',
			food_frequency: '',
			litter_box: '',
			litter_type: '',
			pathologies: '',
			personality: '',
			playroom: -1,
			notes: '',
			owner: null,
			veterinarian: null
		}
	},
	computed: {
		cat_fields_ok: function() {
			if(this.name == '') {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		getCats: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/cats/by_owner/' + self.owner_id,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.rows = response.data;
				self.loading = false
			})
		},
		openNewCatPopup: function() {
			this.id = 0
			this.name = ''
			this.birth_date = ''
			this.gender = ''
			this.food_type = ''
			this.food_frequency = ''
			this.litter_box = ''
			this.litter_type = ''
			this.pathologies = ''
			this.personality = ''
			this.playroom = -1
			this.notes = ''
			this.owner = this.owner_data
			this.veterinarian = null
			this.edit_cat_new = true
			this.edit_cat = true
		},
		openEditCatPopup: function(cat) {
			this.id = cat.ID
			this.name = cat.name
			this.birth_date = cat.birth_date
			this.gender = cat.gender
			this.food_type = cat.food_type
			this.food_frequency = cat.food_frequency
			this.litter_box = cat.litter_box
			this.litter_type = cat.litter_type
			this.pathologies = cat.pathologies
			this.personality = cat.personality
			this.playroom = cat.playroom
			this.notes = cat.notes
			this.owner = cat.owner
			this.veterinarian = cat.veterinarian
			this.edit_cat_new = false
			this.edit_cat = true
		},
		saveCat: function() {
			var self = this

			let owner_id = 0
			if(self.owner != null) {
				owner_id = self.owner.ID
			}

			let veterinarian_id = 0
			if(self.veterinarian != null) {
				veterinarian_id = self.veterinarian.ID
			}

			self.axios.post(
				self.$store.state.base_url + '/api/cats/edit/' + self.id,
				{
					'name': self.name,
					'birth_date': self.birth_date,
					'gender': self.gender,
					'food_type': self.food_type,
					'food_frequency': self.food_frequency,
					'litter_box': self.litter_box,
					'litter_type': self.litter_type,
					'pathologies': self.pathologies,
					'personality': self.personality,
					'playroom': self.playroom,
					'notes': self.notes,
					'id_owner': owner_id,
					'id_veterinarian': veterinarian_id
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function() {
				self.edit_cat = false
				self.getCats()
			})
		},
		openDeleteCatPopup: function(cat) {
			this.id = cat.ID
			this.name = cat.name
			this.birth_date = cat.birth_date
			this.gender = cat.gender
			this.food_type = cat.food_type
			this.food_frequency = cat.food_frequency
			this.litter_box = cat.litter_box
			this.litter_type = cat.litter_type
			this.pathologies = cat.pathologies
			this.personality = cat.personality
			this.playroom = cat.playroom
			this.notes = cat.notes
			this.delete_cat = true
		},
		deleteCat: function() {
			var self = this

			if(self.id != '') {
				self.axios.post(
					self.$store.state.base_url + '/api/cats/delete/' + self.id,
					{},
					{
						headers: { Authorization: `Bearer ${self.$store.state.token}` }
					}
				).then(function() {
					self.delete_cat = false
					self.getCats()
				})
			}
		},
		setOwnerSelected: function(owner) {
			this.owner = owner
		},
		setVeterinarianSelected: function(veterinarian) {
			this.veterinarian = veterinarian
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		if(this.$store.state.token != '') {
			var self = this
			self.loading = true
			self.owner_id = self.$route.params.owner_id;
			self.axios.get(
				self.$store.state.base_url + '/api/owners/data/' + self.owner_id,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.owner_data = response.data;
			})

			this.getCats()
		}
	}
}
</script>

<style type="text/css" scoped>
	.cats_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.actions button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-right: 10px;
	}

	.actions button:last-child {
		margin-right: 0;
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 15px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.header .title {
		font-size: 18px;
		font-weight: 600;
	}
</style>