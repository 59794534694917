<template>
	<div class="select_cat_row">
		<div class="select">
			<vSelect label="label" :options="cats" v-model="selected"></vSelect>
		</div>
		<div class="actions">
			<button v-bind:class="{ disabled: !selected }" @click="openEditCatPopup()"><i class="fa-solid fa-pen-to-square"></i></button>
			<button @click="openNewCatPopup()"><i class="fa-solid fa-plus"></i></button>
		</div>
	</div>
	<vue-final-modal v-model="edit_cat" classes="modal" content-class="modal_box">
		<div class="modal_title">
			<span v-if="edit_cat_new">NUOVO PROPRIETARIO</span>
			<span v-else>MODIFICA PROPRIETARIO</span>
			<button class="modal_close" @click="edit_cat = false">
				<i class="fas fa-times"></i>
			</button>
		</div>
		<div class="modal_content">
			<div class="modal_content_row">
				<label>Nome</label>
				<input type="text" v-model="name" />
			</div>
			<div class="modal_content_row">
				<label>Data di nascita</label>
				<input type="date" v-model="birth_date" />
			</div>
			<div class="modal_content_row">
				<label>Sesso</label>
				<div class="modal_radio">
					<input type="radio" id="gender_m" value="M" v-model="gender" />
					<label for="gender_m">Maschio</label>
					<input type="radio" id="gender_f" value="F" v-model="gender" />
					<label for="gender_f">Femmina</label>
				</div>
			</div>
			<div class="modal_content_row">
				<label>Tipo di cibo</label>
				<input type="text" v-model="food_type" />
			</div>
			<div class="modal_content_row">
				<label>Frequenza somministrazione</label>
				<input type="text" v-model="food_frequency" />
			</div>
			<div class="modal_content_row">
				<label>Tipo cassettina igienica utilizzata</label>
				<div class="modal_radio">
					<input type="radio" id="litter_box_chiusa" value="Chiusa" v-model="litter_box" />
					<label for="litter_box_chiusa">Chiusa</label>
					<input type="radio" id="litter_box_aperta" value="Aperta" v-model="litter_box" />
					<label for="litter_box_aperta">Aperta</label>
				</div>
			</div>
			<div class="modal_content_row">
				<label>Tipo lettiera utilizzata</label>
				<div class="modal_radio">
					<input type="radio" id="litter_type_agglomerante" value="Agglomerante" v-model="litter_type" />
					<label for="aggressive_yes">Agglomerante</label>
					<input type="radio" id="litter_type_silicio" value="Silicio" v-model="litter_type" />
					<label for="aggressive_no">Silicio</label>
				</div>
			</div>
			<div class="modal_content_row">
				<label>Eventuali patologie</label>
				<input type="text" v-model="pathologies" />
			</div>
			<div class="modal_content_row">
				<label>Abitudini e carattere</label>
				<input type="text" v-model="personality" />
			</div>
			<div class="modal_content_row">
				<label>PlayRoom</label>
				<div class="modal_radio">
					<input type="radio" id="playroom_yes" value="1" v-model="playroom" />
					<label for="playroom_yes">Si</label>
					<input type="radio" id="playroom_no" value="0" v-model="playroom" />
					<label for="playroom_no">No</label>
				</div>
			</div>
			<div class="modal_content_row">
				<label>Note</label>
				<textarea v-model="notes"></textarea>
			</div>
			<div class="modal_content_row">
				<label>Proprietario</label>
				<OwnerSelect :preselected="owner" @update:preselected="setOwnerSelected"></OwnerSelect>
			</div>
			<div class="modal_content_row">
				<label>Veterinario</label>
				<VeterinarianSelect :preselected="veterinarian" @update:preselected="setVeterinarianSelected"></VeterinarianSelect>
			</div>
			<div class="modal_content_row">
				<button class="button" :class="{ disabled: !cat_fields_ok }" @click="saveCat()">SALVA</button>
			</div>
		</div>
	</vue-final-modal>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

import OwnerSelect from '@/components/OwnerSelect.vue'
import VeterinarianSelect from '@/components/VeterinarianSelect.vue'

export default {
	name: 'CatSelect',
	components: {
		vSelect,
		OwnerSelect,
		VeterinarianSelect
	},
	props: [
		'preselected'
	],
	emits: [
		'update:preselected'
	],
	data() {
		return {
			cats: [],
			edit_cat: false,
			edit_cat_new: false,
			id: '',
			name: '',
			birth_date: '',
			gender: '',
			food_type: '',
			food_frequency: '',
			litter_box: '',
			litter_type: '',
			pathologies: '',
			personality: '',
			playroom: -1,
			notes: '',
			owner: null,
			veterinarian: null
		}
	},
	computed: {
		selected: {
			get () { return this.preselected },
			set (value) { this.$emit('update:preselected', value) },
		},
		cat_fields_ok: function() {
			if(this.name == '') {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		getCats: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/cats/all',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.cats = response.data;
				self.loading = false
			})
		},
		openNewCatPopup: function() {
			this.id = 0
			this.name = ''
			this.birth_date = ''
			this.gender = ''
			this.food_type = ''
			this.food_frequency = ''
			this.litter_box = ''
			this.litter_type = ''
			this.pathologies = ''
			this.personality = ''
			this.playroom = -1
			this.notes = ''
			this.owner = null
			this.veterinarian = null
			this.edit_cat_new = true
			this.edit_cat = true
		},
		openEditCatPopup: function() {
			this.id = this.selected.ID
			this.name = this.selected.name
			this.birth_date = this.selected.birth_date
			this.gender = this.selected.gender
			this.food_type = this.selected.food_type
			this.food_frequency = this.selected.food_frequency
			this.litter_box = this.selected.litter_box
			this.litter_type = this.selected.litter_type
			this.pathologies = this.selected.pathologies
			this.personality = this.selected.personality
			this.playroom = this.selected.playroom
			this.notes = this.selected.notes
			this.owner = this.selected.owner
			this.veterinarian = this.selected.veterinarian
			this.edit_cat_new = false
			this.edit_cat = true
		},
		saveCat: function() {
			var self = this

			let owner_id = 0
			if(self.owner != null) {
				owner_id = self.owner.ID
			}

			let veterinarian_id = 0
			if(self.veterinarian != null) {
				veterinarian_id = self.veterinarian.ID
			}

			self.axios.post(
				self.$store.state.base_url + '/api/cats/edit/' + self.id,
				{
					'name': self.name,
					'birth_date': self.birth_date,
					'gender': self.gender,
					'food_type': self.food_type,
					'food_frequency': self.food_frequency,
					'litter_box': self.litter_box,
					'litter_type': self.litter_type,
					'pathologies': self.pathologies,
					'personality': self.personality,
					'playroom': self.playroom,
					'notes': self.notes,
					'id_owner': owner_id,
					'id_veterinarian': veterinarian_id
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.edit_cat = false
				self.getCats()

				let new_selected = {
					ID: '',
					name: '',
					birth_date: '',
					gender: '',
					food_type: '',
					food_frequency: '',
					litter_box: '',
					litter_type: '',
					pathologies: '',
					personality: '',
					playroom: '',
					notes: '',
					owner: null,
					veterinarian: null
				}

				if(self.id > 0) {
					new_selected.ID = self.id
				} else {
					new_selected.ID = response.data
				}
				new_selected.name = self.name
				new_selected.label = self.name + ' (' + (self.owner ? self.owner.name : '') + ')'
				new_selected.birth_date = self.birth_date
				new_selected.gender = self.gender
				new_selected.food_type = self.food_type
				new_selected.food_frequency = self.food_frequency
				new_selected.litter_box = self.litter_box
				new_selected.litter_type = self.litter_type
				new_selected.pathologies = self.pathologies
				new_selected.personality = self.personality
				new_selected.playroom = self.playroom
				new_selected.notes = self.notes
				new_selected.owner = self.owner
				new_selected.veterinarian = self.veterinarian

				self.selected = new_selected
			})
		},
		setOwnerSelected: function(owner) {
			this.owner = owner
		},
		setVeterinarianSelected: function(veterinarian) {
			this.veterinarian = veterinarian
		}
	},
	mounted: function() {
		this.getCats()
	}
}
</script>

<style type="text/css" scoped>
	.select_cat_row {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.select {
		flex: 1;
	}

	.actions {
		min-width: 90px;
		max-width: 90px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: 20px;
	}

	.actions button {
		background: #5e72e4;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #5e72e4;
		outline: none;
		cursor: pointer;
		margin-left: 5px;
	}

	.actions button:first-child {
		margin-left: 0px;
	}

	.actions button i {
		padding: 0;
		margin: 0;
		color: #fff;
		font-size: 20px;
	}

	.actions button:first-child i {
		font-size: 16px;
	}

	.actions button:hover {
		background: #fff;
	}

	.actions button:hover i {
		color: #5e72e4;
	}

	.actions button.disabled {
		opacity: 0.5;
		filter: grayscale(1);
		pointer-events: none;
	}
</style>