<template>
	<div class="veterinarians">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="veterinarians_inner">
				<div class="buttons">
					<div>
						<div class="button" @click="downloadExcel()">SCARICA EXCEL</div>
					</div>
					<div>
						<div class="button" @click="openNewVeterinarianPopup()">NUOVO VETERINARIO</div>
					</div>
				</div>
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:search-options="{
						enabled: true,
						placeholder: 'Cerca nella tabella',
					}"
					:pagination-options="{
						enabled: true,
						nextLabel: 'Successiva',
						prevLabel: 'Precedente',
						rowsPerPageLabel: 'Righe per pagina',
						ofLabel: 'di',
						allLabel: 'Tutte',
						perPage: 10,
						perPageDropdown: [10, 20, 50, 100, 200]
					}">
						<template #table-row="props">
							<span class="actions" v-if="props.column.field == 'actions'">
								<button @click="openEditVeterinarianPopup(props.row)"><i class="fa-solid fa-pen-to-square"></i></button>
								<button style="color: #d11507;" @click="openDeleteVeterinarianPopup(props.row)"><i class="fa-solid fa-trash"></i></button>
							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
						</template>
				</vue-good-table>
				<vue-final-modal v-model="edit_veterinarian" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span v-if="edit_veterinarian_new">NUOVO VETERINARIO</span>
						<span v-else>MODIFICA VETERINARIO</span>
						<button class="modal_close" @click="edit_veterinarian = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<label>Nome</label>
							<input type="text" v-model="name" />
						</div>
						<div class="modal_content_row">
							<label>Email</label>
							<input type="text" v-model="email" />
						</div>
						<div class="modal_content_row">
							<label>Recapito Telefonico</label>
							<input type="text" v-model="phone_number" />
						</div>
						<div class="modal_content_row">
							<label>Recapito di Emergenza</label>
							<input type="text" v-model="emergency_number" />
						</div>
						<div class="modal_content_row">
							<label>Città</label>
							<input type="text" v-model="city" />
						</div>
						<div class="modal_content_row">
							<button class="button" :class="{ disabled: !veterinarian_fields_ok }" @click="saveVeterinarian()">SALVA</button>
						</div>
					</div>
				</vue-final-modal>
				<vue-final-modal v-model="delete_veterinarian" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span>RIMUOVI VETERINARIO</span>
						<button class="modal_close" @click="delete_veterinarian = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<p class="message">Vuoi davvero rimuovere il veterinario <b>{{ name }}</b>?</p>
						</div>
						<div class="modal_content_row flex_row flex_end">
							<button class="button" @click="deleteVeterinarian()">CONFERMA</button>
							<button class="button cancel" @click="delete_veterinarian = false">ANNULLA</button>
						</div>
					</div>
				</vue-final-modal>
			</div>
			<div class="loader" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
export default {
	name: 'VeterinariansPage',
	components: {
		TopHeader
	},
	data() {
		return {
			loading: true,
			columns: [
				{
					label: 'Nome',
					field: 'name'
				},
				{
					label: 'Email',
					field: 'email',
					sortable: false,
				},
				{
					label: 'Recapito telefonico',
					field: 'phone_number',
					sortable: false,
				},
				{
					label: 'Recapito emergenza',
					field: 'emergency_number',
					sortable: false,
				},
				{
					label: 'Città',
					field: 'city',
					sortable: false,
				},
				{
					label: '',
					field: 'actions',
					sortable: false,
					width: '70px',
				},
			],
			rows: [],
			edit_veterinarian: false,
			edit_veterinarian_new: false,
			delete_veterinarian: false,
			id: '',
			name: '',
			email: '',
			phone_number: '',
			emergency_number: '',
			city: ''
		}
	},
	computed: {
		veterinarian_fields_ok: function() {
			if(this.name == '') {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		getVeterinarians: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/veterinarians/all',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.rows = response.data;
				self.loading = false
			})
		},
		openNewVeterinarianPopup: function() {
			this.id = 0
			this.name = ''
			this.email = ''
			this.phone_number = ''
			this.emergency_number = ''
			this.city = ''
			this.edit_veterinarian_new = true
			this.edit_veterinarian = true
		},
		openEditVeterinarianPopup: function(veterinarian) {
			this.id = veterinarian.ID
			this.name = veterinarian.name
			this.email = veterinarian.email
			this.phone_number = veterinarian.phone_number
			this.emergency_number = veterinarian.emergency_number
			this.city = veterinarian.city
			this.edit_veterinarian_new = false
			this.edit_veterinarian = true
		},
		saveVeterinarian: function() {
			var self = this

			self.axios.post(
				self.$store.state.base_url + '/api/veterinarians/edit/' + self.id,
				{
					'name': self.name,
					'email': self.email,
					'phone_number': self.phone_number,
					'emergency_number': self.emergency_number,
					'city': self.city
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function() {
				self.edit_veterinarian = false
				self.getVeterinarians()
			})
		},
		openDeleteVeterinarianPopup: function(veterinarian) {
			this.id = veterinarian.ID
			this.name = veterinarian.name
			this.email = veterinarian.email
			this.phone_number = veterinarian.phone_number
			this.emergency_number = veterinarian.emergency_number
			this.city = veterinarian.city
			this.delete_veterinarian = true
		},
		deleteVeterinarian: function() {
			var self = this

			if(self.id != '') {
				self.axios.post(
					self.$store.state.base_url + '/api/veterinarians/delete/' + self.id,
					{},
					{
						headers: { Authorization: `Bearer ${self.$store.state.token}` }
					}
				).then(function() {
					self.delete_veterinarian = false
					self.getVeterinarians()
				})
			}
		},
		downloadExcel() {
			var self = this

			self.axios.get(
				self.$store.state.base_url + '/api/veterinarians/excel',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` },
					responseType: 'blob'
				}
			).then(function(response) {
				const url = URL.createObjectURL(new Blob([response.data], {
					type: 'application/vnd.ms-excel'
				}))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'Veterinari.xlsx')
				document.body.appendChild(link)
				link.click()
				link.remove()
			})
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		if(this.$store.state.token != '') {
			this.getVeterinarians()
		}
	}
}
</script>

<style type="text/css" scoped>
	.veterinarians_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.actions button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-right: 10px;
	}

	.actions button:last-child {
		margin-right: 0;
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}
</style>