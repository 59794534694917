import { createRouter, createWebHashHistory } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import DailyBookingPage from '../views/DailyBookingPage.vue'
import WeeklyBookingPage from '../views/WeeklyBookingPage.vue'
import CatsPage from '../views/CatsPage.vue'
import OwnersPage from '../views/OwnersPage.vue'
import OwnerPrenotationsPage from '../views/OwnerPrenotationsPage.vue'
import VeterinariansPage from '../views/VeterinariansPage.vue'
import SettingsPage from '../views/SettingsPage.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/daily_booking',
    name: 'daily_booking',
    component: DailyBookingPage,
    props: true
  },
  {
    path: '/weekly_booking',
    name: 'weekly_booking',
    component: WeeklyBookingPage
  },
  {
    path: '/cats/:owner_id',
    name: 'cats',
    component: CatsPage
  },
  {
    path: '/owners',
    name: 'owners',
    component: OwnersPage
  },
  {
    path: '/owner_prenotations/:owner_id',
    name: 'owner_prenotations',
    component: OwnerPrenotationsPage
  },
  {
    path: '/veterinarians',
    name: 'veterinarians',
    component: VeterinariansPage
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router