import { createStore } from 'vuex'
import router from './router'

export const store = createStore({
  state() {
    return {
      base_url: 'https://gatthotel.netsons.org/booking_core',
      token: '',
      extended_view: false,
      current_date: ''
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setExtendedView(state, extended_view) {
      state.extended_view = extended_view
    },
    setCurrentDate(state, current_date) {
      state.current_date = current_date
    },
  },
  getters: {
    getToken: state => {
      if(state.token != '')
        return window.parseJwt(state.token)
      else
        return ''
    }
  },
  actions: {
    checkToken(context) {
      let token = context.getters.getToken;
      if((token['exp'] - 30) <= Math.round(new Date().getTime() / 1000)) {
        context.commit('setToken', '');
        router.push('/');
      }
    }
  }
})