<template>
	<div class="login" style="background-image: url('images/background_login.jpg');">
		<div class="login_box">
			<label for="username">Username</label>
			<input type="text" id="username" v-model="username" @keyup.enter="login()" />
			<label for="password">Password</label>
			<input type="password" id="password" v-model="password" @keyup.enter="login()" />
			<button class="button" :class="{ loading: loading }" @click="login()"><i class="fas fa-spinner fa-spin"></i><span>ACCEDI</span></button>
			<div class="message" :class="{ error: status == -1, success: status == 1 }">{{ status_text }}</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
			loading: false,
      username: '',
      password: '',
      new_password: '',
      status: 0,
      status_text: ''
    }
  },
  methods: {
		login() {
      var self = this
      if(self.username == '') {
        self.status = -1
        self.status_text = 'Inserisci la tua username'
        setTimeout(function() {
          self.status = 0
					self.status_text = ''
        }, 3000)
      } else if(self.password == '') {
        self.status = -1
        self.status_text = 'Inserisci la password'
        setTimeout(function() {
          self.status = 0
					self.status_text = ''
        }, 3000)
      } else {
        self.loading = true;
        this.axios.post(self.$store.state.base_url + '/auth', {
          username: self.username,
          password: self.password
        }).then(function(response) {
          if(response.data.status == -1) {
            self.status = -1
            self.status_text = 'Username o password non validi'
            setTimeout(function() {
							self.status = 0
							self.status_text = ''
						}, 3000)
          } else {
            self.$store.commit('setToken', response.data.token);
            self.$cookies.set('token', response.data.token);
            self.status = 1
            self.status_text = 'Login effettuato con successo'
            setTimeout(function() {
              self.$router.push('daily_booking');
            }, 2000)
          }
          self.loading = false;
        })
      }
    }
  }
}
</script>

<style type="text/css" scoped>
	.login {
		width: 100vw;
		height: 100vh;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.login_box {
		width: 400px;
		padding: 40px 50px 45px;
		display: flex;
		flex-direction: column;
		background: #fff;
		border-radius: 6px;
		position: relative;
	}

	label {
		font-weight: 500;
	}

	input[type="text"],
	input[type="password"] {
		border: none;
		outline: none;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		padding: 8px 10px;
		border-radius: 3px;
		margin-bottom: 30px;
		margin-top: 4px;
	}

	.button {
		margin-top: 10px;
		font-size: 16px;
	}

	.button > i {
		display: none;
	}

	.button.loading > span {
		display: none;
	}

	.button.loading > i {
		display: inline;
	}

	.message {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: -44px;
		padding: 8px;
		text-align: center;
		color: #fff;
		font-weight: 600;
		border-radius: 6px;
	}

	.message.error {
		background: #e50000;
	}

	.message.success {
		background: green;
	}
</style>