<template>
	<div class="weekly_booking">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="weekly_booking_inner">
				<div class="days">
					<div class="name">
						<span @click="previousWeek()"><i class="fa-solid fa-circle-left"></i></span>
						<span @click="nextWeek()"><i class="fa-solid fa-circle-right"></i></span>
					</div>
					<div class="day" v-for="(day_prenotations, d_date) in days" v-bind:key="d_date">
						<div class="day_inner" v-html="format_day(d_date)"></div>
					</div>
				</div>
				<div class="box_type" v-for="box_type in booking" v-bind:key="box_type.ID">
					<div class="box_type_inner" v-bind:style="{ borderColor: box_type.color }">
						<div class="boxes">
							<div class="box" v-for="(box, b_index) in box_type.boxes" v-bind:key="b_index">
								<div class="name" v-bind:style="{ backgroundColor: box_type.color }">{{ box_type.name }} n. <span>{{ box.number }}</span></div>
								<div class="day" v-for="(day_prenotations, d_date) in box.prenotations" v-bind:key="d_date">
									<div class="day_inner" v-bind:style="{ background: getBackgroundColor(box_type, box, d_date) }"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'

export default {
	name: 'WeeklyBookingPage',
	components: {
		TopHeader
	},
	data() {
		return {
			loading: false,
			start_date_obj: '',
			start_date: '',
			end_date_obj: '',
			end_date: '',
			booking: []
		}
	},
	computed: {
		days: function() {
			if(this.booking.length > 0) {
				return this.booking[0].boxes[0].prenotations
			} else {
				return []
			}			
		}
	},
	methods: {
		getCalendar: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/booking/periodBooking/' + self.start_date + '/' + self.end_date,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.booking = response.data;
				self.loading = false;
			})
		},
		getBackgroundColor: function(box_type, box, date) {
			if(box.prenotations[date] == 0) {
				return '#89d387';
			} else if(box.prenotations[date] == 1 && box_type.divisible) {
				return '#ffe462';
			} else {
				return '#ff8888';
			}	
		},
		format_day: function(date) {
			date = new Date(date)
			let week_day = date.toLocaleString('it-IT', {weekday: 'long'})
			let month_day = date.toLocaleString('it-IT', {day: 'numeric', month: 'long'})

			return '<span style="font-size: 13px;">' + week_day + '</span><br/>' + month_day;
		},
		previousWeek: function() {
			let first_week_day = new Date(this.start_date_obj)
			first_week_day.setDate(first_week_day.getDate() - 7)
			this.start_date_obj = first_week_day
			this.start_date = first_week_day.toJSON().slice(0, 10)

			let last_next_week_day = new Date(this.end_date_obj)
			last_next_week_day.setDate(last_next_week_day.getDate() - 7)
			this.end_date_obj = last_next_week_day
			this.end_date = last_next_week_day.toJSON().slice(0, 10)

			this.getCalendar()
		},
		nextWeek: function() {
			let first_week_day = new Date(this.start_date_obj)
			first_week_day.setDate(first_week_day.getDate() + 7)
			this.start_date_obj = first_week_day
			this.start_date = first_week_day.toJSON().slice(0, 10)

			let last_next_week_day = new Date(this.end_date_obj)
			last_next_week_day.setDate(last_next_week_day.getDate() + 7)
			this.end_date_obj = last_next_week_day
			this.end_date = last_next_week_day.toJSON().slice(0, 10)

			this.getCalendar()
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		let current_date = new Date()
		let day = current_date.getDay()
		let diff = current_date.getDate() - day + (day == 0 ? -6:1)
		let first_week_day = new Date(current_date.setDate(diff))

		let last_next_week_day = new Date(first_week_day)
		last_next_week_day.setDate(last_next_week_day.getDate() + 14)

		this.start_date_obj = first_week_day
		this.start_date = first_week_day.toJSON().slice(0, 10)
		this.end_date_obj = last_next_week_day
		this.end_date = last_next_week_day.toJSON().slice(0, 10)

		if(this.$store.state.token != '') {
			this.getCalendar()
		}
	},
	activated: function() {
		this.booking = []
		this.getCalendar()
	}
}
</script>

<style type="text/css" scoped>
	.weekly_booking_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.days {
		display: flex;
		margin-bottom: 15px;
	}

	.days .name {
		width: 200px;
		margin-right: 20px;
	}

	.days .day_inner {
		text-align: center;
		font-weight: 600;
		font-size: 15px;
		line-height: 14px;
		text-transform: capitalize;
		border: none;
	}

	.box_type {
		margin-bottom: 20px;
	}

	.box_type .title {
		font-weight: 600;
		margin-bottom: 5px;
	}

	.box {
		display: flex;
		margin-bottom: 5px;
	} 

	.box .name {
		width: 200px;
		color: #fff;
		font-weight: 500;
		padding: 4px 10px;
		margin-right: 20px;
		font-size: 14px;
		border-radius: 3px;
	}

	.box .name span {
		font-weight: 700;
		font-size: 16px;
	}

	.day {
		flex: 1;
		margin-right: 10px;
	}

	.day:last-child {
		margin-right: 0px;
	}

	.day_inner {
		width: 100%;
		height: 27px;
		border-radius: 3px;
		border: 1px solid #00000044;
	}

	.days .name {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 3px;
	}

	.days .name i {
		font-size: 24px;
		cursor: pointer;
		color: #5e72e4;
		margin-left: 10px;
	}
</style>