<template>
	<div class="owners">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="owners_inner">
				<div class="buttons">
					<div>
						<div class="button" @click="downloadExcel()">SCARICA EXCEL</div>
					</div>
					<div>
						<div class="button" @click="openNewOwnerPopup()">NUOVO PROPRIETARIO</div>
					</div>
				</div>
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:search-options="{
						enabled: true,
						placeholder: 'Cerca nella tabella',
					}"
					:pagination-options="{
						enabled: true,
						nextLabel: 'Successiva',
						prevLabel: 'Precedente',
						rowsPerPageLabel: 'Righe per pagina',
						ofLabel: 'di',
						allLabel: 'Tutte',
						perPage: 10,
						perPageDropdown: [10, 20, 50, 100, 200]
					}">
						<template #table-row="props">
							<span class="actions" v-if="props.column.field == 'actions'">
								<button @click="openEditOwnerPopup(props.row)"><i class="fa-solid fa-pen-to-square"></i></button>
								<button style="color: #d11507;" @click="openDeleteOwnerPopup(props.row)"><i class="fa-solid fa-trash"></i></button>
							</span>
							<span class="cats" v-else-if="props.column.field == 'cats'">
								{{props.formattedRow[props.column.field]}}
								<button @click="showOwnerCats(props.row)"><i class="fa-solid fa-eye"></i></button>
							</span>
							<span class="prenotations" v-else-if="props.column.field == 'prenotations'">
								{{props.formattedRow[props.column.field]}}
								<button @click="showOwnerPrenotations(props.row)"><i class="fa-solid fa-eye"></i></button>
							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
						</template>
				</vue-good-table>
				<vue-final-modal v-model="edit_owner" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span v-if="edit_owner_new">NUOVO PROPRIETARIO</span>
						<span v-else>MODIFICA PROPRIETARIO</span>
						<button class="modal_close" @click="edit_owner = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<label>Nome</label>
							<input type="text" v-model="name" @focusout="checkOwnerName()" />
							<div class="modal_content_row_error" v-if="owner_name_error"><i class="fa-solid fa-triangle-exclamation"></i> Nome già esistente</div>
						</div>
						<div class="modal_content_row">
							<label>Indirizzo</label>
							<input type="text" v-model="address" />
						</div>
						<div class="modal_content_row">
							<label>Numero di Telefono</label>
							<input type="text" v-model="phone_number" />
						</div>
						<div class="modal_content_row">
							<label>Numero di Cellulare</label>
							<input type="text" v-model="mobile_number" />
						</div>
						<div class="modal_content_row">
							<label>Email</label>
							<input type="text" v-model="email" />
						</div>
						<div class="modal_content_row">
							<h4>RECAPITI DURANTE IL PERIODO DI ASSENZA</h4>
						</div>
						<div class="modal_content_row">
							<label>Cellulare personale</label>
							<input type="text" v-model="personal_number" />
						</div>
						<div class="modal_content_row">
							<label>Contatto di emergenza</label>
							<input type="text" v-model="emergence_contact" />
						</div>
						<div class="modal_content_row">
							<button class="button" :class="{ disabled: !owner_fields_ok }" @click="saveOwner()">SALVA</button>
						</div>
					</div>
				</vue-final-modal>
				<vue-final-modal v-model="delete_owner" classes="modal" content-class="modal_box">
					<div class="modal_title">
						<span>RIMUOVI PROPRIETARIO</span>
						<button class="modal_close" @click="delete_owner = false">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<div class="modal_content">
						<div class="modal_content_row">
							<p class="message">Vuoi davvero rimuovere il proprietario <b>{{ name }}</b>?</p>
						</div>
						<div class="modal_content_row flex_row flex_end">
							<button class="button" @click="deleteOwner()">CONFERMA</button>
							<button class="button cancel" @click="delete_owner = false">ANNULLA</button>
						</div>
					</div>
				</vue-final-modal>
			</div>
			<div class="loader" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
export default {
	name: 'OwnersPage',
	components: {
		TopHeader
	},
	data() {
		return {
			loading: true,
			columns: [
				{
					label: 'Nome',
					field: 'name'
				},
				{
					label: 'Indirizzo',
					field: 'address',
					sortable: false,
				},
				{
					label: 'Numero di Telefono',
					field: 'phone_number',
					sortable: false,
				},
				{
					label: 'Numero di Cellulare',
					field: 'mobile_number',
					sortable: false,
				},
				{
					label: 'Email',
					field: 'email',
					sortable: false,
				},
				{
					label: 'Gatti',
					field: 'cats',
					sortable: false,
					thClass: 'col_title_center'
				},
				{
					label: 'Prenotazioni',
					field: 'prenotations',
					sortable: false,
					thClass: 'col_title_center'
				},
				{
					label: '',
					field: 'actions',
					sortable: false,
					width: '70px',
				},
			],
			rows: [],
			edit_owner: false,
			edit_owner_new: false,
			delete_owner: false,
			id: '',
			name: '',
			address: '',
			phone_number: '',
			mobile_number: '',
			email: '',
			personal_number: '',
			emergence_contact: '',
			owner_name_error: false
		}
	},
	computed: {
		owner_fields_ok: function() {
			if(this.name == '') {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		getOwners: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/owners/all',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.rows = response.data;
				self.loading = false
			})
		},
		openNewOwnerPopup: function() {
			this.id = 0
			this.name = ''
			this.address = ''
			this.phone_number = ''
			this.mobile_number = ''
			this.email = ''
			this.personal_number = ''
			this.emergence_contact = ''
			this.edit_owner_new = true
			this.edit_owner = true
			this.owner_name_error = false
		},
		openEditOwnerPopup: function(owner) {
			this.id = owner.ID
			this.name = owner.name
			this.address = owner.address
			this.phone_number = owner.phone_number
			this.mobile_number = owner.mobile_number
			this.email = owner.email
			this.personal_number = owner.personal_number
			this.emergence_contact = owner.emergence_contact
			this.edit_owner_new = false
			this.edit_owner = true
			this.owner_name_error = false
		},
		saveOwner: function() {
			var self = this

			self.axios.post(
				self.$store.state.base_url + '/api/owners/edit/' + self.id,
				{
					'name': self.name,
					'address': self.address,
					'phone_number': self.phone_number,
					'mobile_number': self.mobile_number,
					'email': self.email,
					'personal_number': self.personal_number,
					'emergence_contact': self.emergence_contact
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function() {
				self.edit_owner = false
				self.getOwners()
			})
		},
		checkOwnerName: function() {
			var self = this

			self.owner_name_error = false

			self.axios.post(
				self.$store.state.base_url + '/api/owners/check/' + self.id,
				{
					'name': self.name
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				if(response.data >= 1) {
					self.owner_name_error = true
				} else {
					self.owner_name_error = false
				}
			})
		},
		openDeleteOwnerPopup: function(owner) {
			this.id = owner.ID
			this.name = owner.name
			this.address = owner.address
			this.phone_number = owner.phone_number
			this.mobile_number = owner.mobile_number
			this.email = owner.email
			this.personal_number = owner.personal_number
			this.emergence_contact = owner.emergence_contact
			this.delete_owner = true
		},
		deleteOwner: function() {
			var self = this

			if(self.id != '') {
				self.axios.post(
					self.$store.state.base_url + '/api/owners/delete/' + self.id,
					{},
					{
						headers: { Authorization: `Bearer ${self.$store.state.token}` }
					}
				).then(function() {
					self.delete_owner = false
					self.getOwners()
				})
			}
		},
		showOwnerCats(owner) {
			this.$router.push({
				path: `/cats/${owner.ID}`
			})
		},
		showOwnerPrenotations(owner) {
			this.$router.push({
				path: `/owner_prenotations/${owner.ID}`
			})
		},
		downloadExcel() {
			var self = this

			self.axios.get(
				self.$store.state.base_url + '/api/owners/excel',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` },
					responseType: 'blob'
				}
			).then(function(response) {
				const url = URL.createObjectURL(new Blob([response.data], {
					type: 'application/vnd.ms-excel'
				}))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'Proprietari.xlsx')
				document.body.appendChild(link)
				link.click()
				link.remove()
			})
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		if(this.$store.state.token != '') {
			this.getOwners()
		}
	}
}
</script>

<style type="text/css" scoped>
	.owners_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.cats {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #000;
	}

	.cats button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-left: 5px;
	}

	.prenotations {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #000;
	}

	.prenotations button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-left: 5px;
	}

	.actions button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-right: 10px;
	}

	.actions button:last-child {
		margin-right: 0;
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}
</style>

<style type="text/css">
	.col_title_center {
		text-align: center !important;
		padding-right: 0.75em !important;
	}
</style>