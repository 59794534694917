<template>
	<div class="prenotations">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="prenotations_inner">
				<div class="header">
					<div class="title">Prenotazioni di {{ owner_data.name }}</div>
				</div>
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:search-options="{
						enabled: true,
						placeholder: 'Cerca nella tabella',
					}">
						<template #table-row="props">
							<span class="date" v-if="props.column.field == 'start_date_formatted'">
								{{props.formattedRow[props.column.field]}}
								<button @click="showBooking(props.row.start_date)"><i class="fa-solid fa-eye"></i></button>
							</span>
							<span class="date" v-else-if="props.column.field == 'end_date_formatted'">
								{{props.formattedRow[props.column.field]}}
								<button @click="showBooking(props.row.end_date)"><i class="fa-solid fa-eye"></i></button>
							</span>
							<span class="date" v-else-if="props.column.field == 'box'">
								{{props.formattedRow['box']['name']}} -> &nbsp;<b :style="{color: props.formattedRow['box']['color']}">Box {{props.formattedRow['box']['number']}}</b>

							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
						</template>
				</vue-good-table>
			</div>
			<div class="loader" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'

export default {
	name: 'OwnerPrenotationsPage',
	components: {
		TopHeader
	},
	data() {
		return {
			loading: true,
			owner_id: 0,
			owner_data: '',
			columns: [
				{
					label: 'Gatto',
					field: 'cat'
				},
				{
					label: 'Box',
					field: 'box'
				},
				{
					label: 'Dal',
					field: 'start_date_formatted'
				},
				{
					label: 'Al',
					field: 'end_date_formatted'
				}
			],
			rows: []
		}
	},
	methods: {
		getOwnerPrenotations: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/owners/prenotations/' + self.owner_id,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.rows = response.data;
				self.loading = false
			})
		},
		showBooking(date) {
			this.$store.commit('setExtendedView', true)
			this.$store.commit('setCurrentDate', date)

			this.$router.push({
				path: `/daily_booking/`
			})
		},
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		if(this.$store.state.token != '') {
			var self = this
			self.loading = true
			self.owner_id = self.$route.params.owner_id;
			self.axios.get(
				self.$store.state.base_url + '/api/owners/data/' + self.owner_id,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.owner_data = response.data;
			})

			this.getOwnerPrenotations()
		}
	}
}
</script>

<style type="text/css" scoped>
	.prenotations_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.date {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #000;
	}

	.date button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
		margin-left: 15px;
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 15px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.header .title {
		font-size: 18px;
		font-weight: 600;
	}
</style>