<template>
	<div class="select_owner_row">
		<div class="select">
			<vSelect label="name" :options="owners" v-model="selected"></vSelect>
		</div>
		<div class="actions">
			<button v-bind:class="{ disabled: !selected }" @click="openEditOwnerPopup()"><i class="fa-solid fa-pen-to-square"></i></button>
			<button @click="openNewOwnerPopup()"><i class="fa-solid fa-plus"></i></button>
		</div>
	</div>
	<vue-final-modal v-model="edit_owner" classes="modal" content-class="modal_box">
		<div class="modal_title">
			<span v-if="edit_owner_new">NUOVO PROPRIETARIO</span>
			<span v-else>MODIFICA PROPRIETARIO</span>
			<button class="modal_close" @click="edit_owner = false">
				<i class="fas fa-times"></i>
			</button>
		</div>
		<div class="modal_content">
			<div class="modal_content_row">
				<label>Nome</label>
				<input type="text" v-model="name" />
			</div>
			<div class="modal_content_row">
				<label>Indirizzo</label>
				<input type="text" v-model="address" />
			</div>
			<div class="modal_content_row">
				<label>Numero di Telefono</label>
				<input type="text" v-model="phone_number" />
			</div>
			<div class="modal_content_row">
				<label>Numero di Cellulare</label>
				<input type="text" v-model="mobile_number" />
			</div>
			<div class="modal_content_row">
				<label>Email</label>
				<input type="text" v-model="email" />
			</div>
			<div class="modal_content_row">
				<h4>RECAPITI DURANTE IL PERIODO DI ASSENZA</h4>
			</div>
			<div class="modal_content_row">
				<label>Cellulare personale</label>
				<input type="text" v-model="personal_number" />
			</div>
			<div class="modal_content_row">
				<label>Contatto di emergenza</label>
				<input type="text" v-model="emergence_contact" />
			</div>
			<div class="modal_content_row">
				<button class="button" :class="{ disabled: !owner_fields_ok }" @click="saveOwner()">SALVA</button>
			</div>
		</div>
	</vue-final-modal>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

export default {
	name: 'OwnerSelect',
	components: {
		vSelect
	},
	props: [
		'preselected'
	],
	emits: [
		'update:preselected'
	],
	data() {
		return {
			owners: [],
			edit_owner: false,
			edit_owner_new: false,
			id: '',
			name: '',
			address: '',
			phone_number: '',
			mobile_number: '',
			email: '',
			personal_number: '',
			emergence_contact: ''
		}
	},
	computed: {
		selected: {
			get () { return this.preselected },
			set (value) { this.$emit('update:preselected', value) },
		},
		owner_fields_ok: function() {
			if(this.name == '') {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		getOwners: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/owners/all',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.owners = response.data;
				self.loading = false
			})
		},
		openNewOwnerPopup: function() {
			this.id = 0
			this.name = ''
			this.address = ''
			this.phone_number = ''
			this.mobile_number = ''
			this.email = ''
			this.personal_number = ''
			this.emergence_contact = ''
			this.edit_owner_new = true
			this.edit_owner = true
		},
		openEditOwnerPopup: function() {
			this.id = this.selected.ID
			this.name = this.selected.name
			this.address = this.selected.address
			this.phone_number = this.selected.phone_number
			this.mobile_number = this.selected.mobile_number
			this.email = this.selected.email
			this.personal_number = this.selected.personal_number
			this.emergence_contact = this.selected.emergence_contact
			this.edit_owner_new = false
			this.edit_owner = true
		},
		saveOwner: function() {
			var self = this

			self.axios.post(
				self.$store.state.base_url + '/api/owners/edit/' + self.id,
				{
					'name': self.name,
					'address': self.address,
					'phone_number': self.phone_number,
					'mobile_number': self.mobile_number,
					'email': self.email,
					'personal_number': self.personal_number,
					'emergence_contact': self.emergence_contact
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.edit_owner = false
				self.getOwners()

				let new_selected = {
					ID: '',
					name: '',
					address: '',
					phone_number: '',
					mobile_number: '',
					email: '',
					personal_number: '',
					emergence_contact: ''
				}

				if(self.id > 0) {
					new_selected.ID = self.id
				} else {
					new_selected.ID = response.data
				}
				new_selected.name = self.name
				new_selected.address = self.address
				new_selected.phone_number = self.phone_number
				new_selected.mobile_number = self.mobile_number
				new_selected.email = self.email
				new_selected.personal_number = self.personal_number
				new_selected.emergence_contact = self.emergence_contact

				self.selected = new_selected
			})
		}
	},
	mounted: function() {
		this.getOwners()
	}
}
</script>

<style type="text/css" scoped>
	.select_owner_row {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.select {
		flex: 1;
	}

	.actions {
		min-width: 90px;
		max-width: 90px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: 20px;
	}

	.actions button {
		background: #5e72e4;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #5e72e4;
		outline: none;
		cursor: pointer;
		margin-left: 5px;
	}

	.actions button:first-child {
		margin-left: 0px;
	}

	.actions button i {
		padding: 0;
		margin: 0;
		color: #fff;
		font-size: 20px;
	}

	.actions button:first-child i {
		font-size: 16px;
	}

	.actions button:hover {
		background: #fff;
	}

	.actions button:hover i {
		color: #5e72e4;
	}

	.actions button.disabled {
		opacity: 0.5;
		filter: grayscale(1);
		pointer-events: none;
	}
</style>