<template>
  <router-view v-slot="{ Component }">
    <keep-alive exclude="CatsPage,OwnerPrenotationsPage,SettingsPage">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  name: 'App',
  mounted: function() {
    var self = this;

    if(self.$cookies.get('token') && self.$cookies.get('token').length > 20) {
      let token = window.parseJwt(self.$cookies.get('token'));
      if(token) {
        self.$store.commit('setToken', self.$cookies.get('token'));
      }
    } else {
      self.$router.push('/');
    }
  }
}
</script>

<style type="text/css">
  body, * {
    margin: 0;
    padding: 0;
    font-family: 'Raleway';
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    font-size: 16px;
    box-sizing: border-box;
  }

  body {
    overflow-y: scroll;
  }

  .noselect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .hidden {
    opacity: 0;
  }

  .container {
    width: 1600px;
    max-width: 95%;
    margin: 0 auto;
  }

  .loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffffee;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader i {
    font-size: 36px;
  }

  .button {
    border-radius: 3px;
    border: 2px solid #5e72e4;
    outline: none;
    background: #5e72e4;
    color: #fff;
    padding: 8px 14px;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .button:hover {
    background: #fff;
    color: #5e72e4;
  }

  .button.loading {
    pointer-events: none;
  }

  .button span {
    display: inline-block;
    line-height: 22px;
  }

  .button i {
    font-size: 20px;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_box {
    width: 600px;
    background: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.25);
  }

  .modal_title {
    background: #5e72e4;
    padding: 15px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal_title span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .modal_close {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .modal_close > i {
    color: #FFFFFF;
    font-size: 22px;
  }

  .modal_content {
    padding: 35px;
    text-align: center;
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
  }

  .modal_content_row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
  }

  .modal_content_row.flex_row {
    flex-direction: row;
  }

  .modal_content_row.flex_end {
    justify-content: flex-end;
  }

  .modal_content_row:last-child {
    margin-bottom: 0px;
  }

  .modal_content_row label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .modal_content_row input[type="text"] {
    border: none;
    outline: none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    width: 100%;
    padding: 8px 10px;
    border-radius: 3px;
  }

  .modal_content_row input[type="date"],
  .modal_content_row input[type="time"] {
    border: none;
    outline: none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    padding: 8px 10px;
    border-radius: 3px;
  }

  .modal_content_row textarea {
    border: none;
    outline: none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    width: 100%;
    padding: 8px 10px;
    border-radius: 3px;
    width: 100%;
    height: 100px;
    resize: none;
  }

  .modal_content_row h4 {
    margin-top: 20px;
  }

  .modal_content_row .modal_radio {
    display: flex;
    align-items: center;
  }

  .modal_content_row .modal_radio input {
    cursor: pointer;
  }

  .modal_content_row .modal_radio label {
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: 5px;
  }

  .modal_content_row .modal_radio label + input {
    margin-left: 20px;
  }

  .modal_content_row .modal_checkbox {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .modal_content_row .modal_checkbox input {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  .modal_content_row .modal_checkbox label {
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: 8px;
  }

  .modal_content_row .button {
    align-self: flex-end;
    margin-top: 20px;
  }

  .modal_content_row .button.disabled {
    opacity: 0.5;
    filter: grayscale(1);
    pointer-events: none;
  }

  .modal_content_row .button.cancel {
    background: #fff;
    color: #5e72e4;
    margin-left: 15px;
  }

  .modal_content_row .button.cancel:hover {
    background: #5e72e4;
    color: #fff;
  }

  .modal_content_row .button > i {
    display: none;
  }

  .modal_content_row .button.loading {
    pointer-events: none;
  }

  .modal_content_row .button.loading > span {
    display: none;
  }

  .modal_content_row .button.loading > i {
    display: inline;
  }

  .modal_content_row_error {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: red;
    font-weight: 500;
  }

  .modal_content_col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 25px;
  }

  .modal_content_col:last-child {
    margin-right: 0px;
  }

  .modal_error {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_error_box {
    width: 540px;
    background: #e50000;
    color: #fff;
    box-shadow: 0 10px 30px rgb(0 0 0 / 25%);
    padding: 30px 50px;
    position: relative;
  }

  .modal_error_content {
    text-align: center;
  }

  .modal_error_close {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 10px;
  }

  .modal_error_close > i {
    color: #FFFFFF;
    font-size: 22px;
  }
</style>