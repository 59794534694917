<template>
	<div class="daily_booking">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="daily_booking_inner">
				<div class="settings_row">
					<div class="switch_view">
						<span>Ridotta</span>
						<label class="switch">
							<input type="checkbox" v-model="extended_view">
							<span class="slider round"></span>
						</label>
						<span>Estesa</span>
					</div>
					<div class="current_date">
						<span @click="previousDate()"><i class="fa-solid fa-circle-left"></i></span>
						<input type="date" v-model="current_date" />
						<span @click="nextDate()"><i class="fa-solid fa-circle-right"></i></span>
					</div>					
				</div>
				<div class="box_type" v-for="box_type in booking" v-bind:key="box_type.ID">
					<div class="box_type_inner" v-bind:style="{ borderColor: box_type.color }">
						<div class="title" v-bind:style="{ color: box_type.color }">{{ box_type.name }}</div>
						<div class="boxes" v-if="extended_view">
							<div class="box" v-for="(box, b_index) in box_type.boxes" v-bind:key="b_index">
								<div class="box_inner" v-bind:style="{ borderColor: box_type.color, background: getBackgroundColor(box_type, box) }">
									<div class="number" v-bind:style="{ backgroundColor: box_type.color }">{{ box.number }}</div>
									<div class="prenotations">
										<div class="prenotation" v-for="(prenotation, p_index) in box.prenotations" v-bind:key="p_index">
											<div class="prenotation_inner" v-bind:style="{ backgroundColor: prenotation.start_date == current_date ? '#b1c9ff' : 'transparent' }">
												<div class="actions">
													<div class="edit" @click="openEditPrenotationPopup(box_type, box, prenotation)"><i class="fa-solid fa-pen-to-square"></i></div>
													<div class="delete" @click="openDeletePrenotationPopup(box_type, box, prenotation)"><i class="fa-solid fa-trash"></i></div>
												</div>
												<div class="cat_owner" v-if="prenotation.owner">{{ prenotation.owner.name }} ({{ prenotation.owner.cats }})</div>
												<div class="cat_owner" v-else>nessun proprietario</div>
												<div class="cat_name">{{ prenotation.cat.name }}</div>
												<div class="cat_litter"><span>Tipologia cassetta:</span><br/>{{ prenotation.cat.litter_box ? prenotation.cat.litter_box : '-' }}</div>
												<div class="cat_litter"><span>Tipologia lettiera:</span><br/>{{ prenotation.cat.litter_type ? prenotation.cat.litter_type : '-' }}</div>
												<div class="cat_playroom">
													<i class="fa-solid fa-circle-check" style="color: green" v-if="prenotation.cat.playroom == 1"></i>
													<i class="fa-solid fa-circle-xmark" style="color: red" v-else-if="prenotation.cat.playroom == 0"></i>
													<i class="fa-solid fa-circle-question" v-else-if="prenotation.cat.playroom == -1"></i>
													<span>PlayRoom</span>
												</div>
												<div class="cat_notes" @click="showCatNotesPopup(prenotation)"><span>Note:</span><br/>{{ prenotation.cat.notes }}</div>
												<div class="start_date" v-if="prenotation.start_date != current_date"><span>Dal</span> {{ prenotation.start_date_formatted }}</div>
												<div class="start_date" v-else><span>Da oggi alle ore</span> {{ prenotation.start_time ? prenotation.start_time : '-' }}</div>
												<div class="end_date"><span>Al</span> {{ prenotation.end_date_formatted }}</div>
											</div>
										</div>
										<div class="empty_slot" v-for="index in (getBoxSlots(box_type) - getBoxPrenotationsCount(box))" v-bind:key="index">
											<div class="add" @click="openNewPrenotationPopup(box_type, box)"><i class="fa-solid fa-plus"></i></div>
										</div>
									</div>
									<div class="cat_change_day" :class="{ not_empty: box.ending_prenotations.length > 0 }">
										<div class="cat_change_day_row_1">
											<span>In uscita</span>
										</div>
										<div class="cat_change_day_row_2" v-for="(ending_prenotation, ep_index) in box.ending_prenotations" v-bind:key="ep_index">
											<b>{{ ending_prenotation.cat.name }}</b> (<i v-if="ending_prenotation.owner">{{ ending_prenotation.owner.name }}</i>) <span v-if="ending_prenotation.end_time">alle ore <b>{{ ending_prenotation.end_time }}</b></span>
										</div>
										<div class="cat_change_day_row_2" v-if="box.ending_prenotations.length == 0">-</div>
									</div>
								</div>
							</div>
						</div>
						<div class="boxes" v-else>
							<div class="reduced_box" v-for="(box, b_index) in box_type.boxes" v-bind:key="b_index">
								<div class="reduced_box_inner" v-bind:style="{ borderColor: box_type.color, background: getBackgroundColor(box_type, box) }">
									{{ box.number }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="loader" v-if="loading">
				<i class="fas fa-spinner fa-spin"></i>
			</div>
			<vue-final-modal v-model="edit_prenotation" classes="modal" content-class="modal_box">
				<div class="modal_title">
					<span v-if="edit_prenotation_new">NUOVA PRENOTAZIONE</span>
					<span v-else>MODIFICA PRENOTATIONE</span>
					<button class="modal_close" @click="edit_prenotation = false">
						<i class="fas fa-times"></i>
					</button>
				</div>
				<div class="modal_content">
					<div class="modal_content_row">
						<label>Box</label>
						<span>{{ box_type.name }} -> <b>Box {{ box.number }}</b></span>
					</div>
					<div class="modal_content_row">
						<label>Gatto</label>
						<CatSelect :preselected="cat" @update:preselected="setCatSelected"></CatSelect>
					</div>
					<div class="modal_content_row flex_row">
						<div class="modal_content_col">
							<label>Dal</label>
							<input type="date" v-model="start_date" />
						</div>
						<div class="modal_content_col">
							<label>Orario</label>
							<input type="time" v-model="start_time" />
						</div>
					</div>
					<div class="modal_content_row flex_row">
						<div class="modal_content_col">
							<label>Al</label>
							<input type="date" v-model="end_date" />
						</div>
						<div class="modal_content_col">
							<label>Orario (del giorno successivo)</label>
							<input type="time" v-model="end_time" />
						</div>
					</div>
					<div class="modal_content_row" v-if="box_type.divisible">
						<div class="modal_checkbox">
							<input type="checkbox" id="full_box" value="1" v-model="full_box" />
							<label for="full_box">Occupa l'intera gabbia</label>
						</div>
					</div>
					<div class="modal_content_row">
						<button class="button" :class="{ loading: save_loading, disabled: !prenotation_fields_ok }" @click="savePrenotation()"><i class="fas fa-spinner fa-spin"></i><span>SALVA</span></button>
					</div>
				</div>
			</vue-final-modal>
			<vue-final-modal v-model="edit_prenotation_error" classes="modal_error" content-class="modal_error_box">
				<button class="modal_error_close" @click="edit_prenotation_error = false">
					<i class="fas fa-times"></i>
				</button>
				<div class="modal_error_content">
					Box pieno nei seguenti giorni:<br/>
					<b>{{ prenotation_error_dates_str }}</b>
				</div>
			</vue-final-modal>
			<vue-final-modal v-model="delete_prenotation" classes="modal" content-class="modal_box">
				<div class="modal_title">
					<span>CANCELLA PRENOTAZIONE</span>
					<button class="modal_close" @click="delete_prenotation = false">
						<i class="fas fa-times"></i>
					</button>
				</div>
				<div class="modal_content">
					<div class="modal_content_row">
						<p class="message">Vuoi davvero cancellare la prenotazione di <b>{{ cat.name }}</b>?</p>
					</div>
					<div class="modal_content_row flex_row flex_end">
						<button class="button" @click="deletePrenotation()">CONFERMA</button>
						<button class="button cancel" @click="delete_prenotation = false">ANNULLA</button>
					</div>
				</div>
			</vue-final-modal>
			<vue-final-modal v-model="show_cat_notes" classes="modal" content-class="modal_box">
				<div class="modal_title">
					<span>NOTE</span>
					<button class="modal_close" @click="show_cat_notes = false">
						<i class="fas fa-times"></i>
					</button>
				</div>
				<div class="modal_content">
					{{ current_cat_notes }}
				</div>
			</vue-final-modal>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
import CatSelect from '@/components/CatSelect.vue'

export default {
	name: 'DailyBookingPage',
	components: {
		TopHeader,
		CatSelect
	},
	data() {
		return {
			loading: false,
			save_loading: false,
			booking: [],
			edit_prenotation: false,
			edit_prenotation_new: false,
			delete_prenotation: false,
			edit_prenotation_error: false,
			box_type: false,
			box: false,
			cat: false,
			start_date: '',
			start_time: '',
			end_date: '',
			full_box: 0,
			prenotation_error_dates: '',
			show_cat_notes: false,
			current_cat_notes: ''
		}
	},
	computed: {
		extended_view: {
			get () { return this.$store.state.extended_view },
			set (value) { this.$store.commit('setExtendedView', value) }
		},
		current_date: {
			get () { return this.$store.state.current_date },
			set (value) { this.$store.commit('setCurrentDate', value) }
		},
		prenotation_fields_ok: function() {
			if(
				this.cat == null
				|| ((new Date(this.start_date) == "Invalid Date") || isNaN(new Date(this.start_date)))
				|| ((new Date(this.end_date) == "Invalid Date") || isNaN(new Date(this.end_date)))
			) {
				return false
			} else {
				return true
			}
		},
		prenotation_error_dates_str: function() {
			if(Array.isArray(this.prenotation_error_dates)) {
				let str = this.prenotation_error_dates.join(', ')
				return (str.length > 109) ? str.slice(0, 108) + '...' : str
			} else {
				return ''
			}
		}
	},
	watch: {
		current_date() {
			this.getBoxes()
		}
	},
	methods: {
		getBoxes: function() {
			var self = this

			self.loading = true

			self.axios.get(
				self.$store.state.base_url + '/api/booking/dayBooking/' + self.current_date,
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				self.booking = response.data;
				self.loading = false;
			})
		},
		getBoxPrenotationsCount: function(box) {
			let prenotations_count = 0;
			for(let i = 0; i < box.prenotations.length; i++) {
				if(box.prenotations[i].full_box) {
					prenotations_count += 2;
				} else {
					prenotations_count += 1;
				}
			}
			return prenotations_count;
		},
		getBoxSlots: function(box_type) {
			if(box_type.divisible) {
				return 2;
			} else {
				return 1;
			}
		},
		getBackgroundColor: function(box_type, box) {
			if(box.prenotations) {
				if(box.prenotations.length == 0) {
					return '#d7ffd6';
				} else if(this.getBoxPrenotationsCount(box) == 1 && box_type.divisible) {
					return '#fff4d3';
				} else {
					return '#ffd3d3';
				}
			}			
		},
		openNewPrenotationPopup: function(box_type, box) {
			this.id = 0
			this.box_type = box_type
			this.box = box
			this.cat = null
			this.start_date = this.current_date
			this.start_time = ''
			this.end_date = this.current_date
			this.end_time = ''
			this.full_box = 0
			this.edit_prenotation_new = true
			this.edit_prenotation = true
		},
		openEditPrenotationPopup: function(box_type, box, prenotation) {
			this.id = prenotation.ID
			this.box_type = box_type
			this.box = box
			this.cat = prenotation.cat
			this.start_date = prenotation.start_date
			this.start_time = prenotation.start_time
			this.end_date = prenotation.end_date
			this.end_time = prenotation.end_time
			this.full_box = prenotation.full_box
			this.edit_prenotation_new = false
			this.edit_prenotation = true
		},
		savePrenotation: function() {
			var self = this

			let cat_id = 0
			if(self.cat != null) {
				cat_id = self.cat.ID
			}

			self.save_loading = true

			self.axios.post(
				self.$store.state.base_url + '/api/booking/savePrenotation/' + self.id,
				{
					'id_box': self.box.ID,
					'id_cat': cat_id,
					'start_date': self.start_date,
					'start_time': self.start_time,
					'end_date': self.end_date,
					'end_time': self.end_time,
					'full_box': self.full_box
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				if(response.data.res == 1) {
					self.edit_prenotation = false
					self.getBoxes()
				} else if(response.data.res == -1) {
					self.prenotation_error_dates = response.data.data
					self.edit_prenotation_error = true
				}
				self.save_loading = false
			})
		},
		editPrenotation: function(prenotation_id) {
			var self = this

			let cat_id = 0
			if(self.cat != null) {
				cat_id = self.cat.ID
			}

			self.save_loading = true

			self.axios.post(
				self.$store.state.base_url + '/api/booking/editPrenotation/' + prenotation_id,
				{
					'id_box': self.box.ID,
					'id_cat': cat_id,
					'start_date': self.start_date,
					'start_time': self.start_time,
					'end_date': self.end_date,
					'end_time': self.end_time,
					'full_box': self.full_box
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				if(response.data.res == 1) {
					self.edit_prenotation = false
					self.getBoxes()
				} else if(response.data.res == -1) {
					self.prenotation_error_dates = response.data.data
					self.edit_prenotation_error = true
				}
				self.save_loading = false
			})
		},
		openDeletePrenotationPopup: function(box_type, box, prenotation) {
			this.id = prenotation.ID
			this.box_type = box_type
			this.box = box
			this.cat = prenotation.cat
			this.start_date = prenotation.start_date
			this.start_time = prenotation.start_time
			this.end_date = prenotation.end_date
			this.end_time = prenotation.end_time
			this.full_box = prenotation.full_box
			this.delete_prenotation = true
		},
		deletePrenotation: function() {
			var self = this

			if(self.id != '') {
				self.axios.post(
					self.$store.state.base_url + '/api/booking/deletePrenotation/' + self.id,
					{},
					{
						headers: { Authorization: `Bearer ${self.$store.state.token}` }
					}
				).then(function() {
					self.delete_prenotation = false
					self.getBoxes()
				})
			}
		},
		setCatSelected: function(cat) {
			this.cat = cat
		},
		previousDate: function() {
			let d = new Date(this.current_date)
			d.setDate(d.getDate()-1)
			this.current_date = d.toISOString().split('T')[0]
		},
		nextDate: function() {
			let d = new Date(this.current_date)
			d.setDate(d.getDate()+1)
			this.current_date = d.toISOString().split('T')[0]
		},
		showCatNotesPopup: function(prenotation) {
			this.current_cat_notes = prenotation.cat.notes;
			this.show_cat_notes = true;
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')

		if(this.current_date == '') {
			this.current_date = new Date().toJSON().slice(0, 10)
		}		

		if(this.$store.state.token != '') {
			this.getBoxes()
		}
	}
}
</script>

<style type="text/css" scoped>
	.daily_booking_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.settings_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
	}

	.switch_view {
		display: flex;
		align-items: center;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
		margin-left: 15px;
		margin-right: 15px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #5e72e4;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #5e72e4;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #5e72e4;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

	.current_date {		
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.current_date input {
		border: none;
        outline: none;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        padding: 8px 10px;
        border-radius: 3px;
        margin-left: 15px;
        margin-right: 15px;
	}

	.current_date i {
		font-size: 20px;
		cursor: pointer;
		color: #5e72e4;
	}

	.box_type {
		margin-bottom: 40px;
	}

	.box_type_inner {
		position: relative;
		border: 2px solid #fff;
		border-radius: 6px;
		padding: 30px 25px 10px 25px;
	}

	.box_type_inner > .title {
		position: absolute;
		top: -12px;
		font-weight: 700;
		background: #fff;
		padding: 0 4px;
		font-size: 17px;
	}

	.boxes {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.box {
		min-width: calc(50% - 10px);
		max-width: calc(50% - 10px);
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.box:nth-child(2n) {
		margin-right: 0px;
	}

	.box .box_inner {
		border: 2px solid #fff;
		border-radius: 6px;		
		position: relative;
	}

	.box .number {
		position: absolute;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		border-radius: 50%;
		font-weight: 500;
		font-size: 18px;
		line-height: 14px;
		left: -12px;
		top: -12px;
		z-index: 2;
	}

	.box .prenotations {
		display: flex;
		justify-content: space-between;
	}

	.box .prenotation {
		flex: 1;
		min-width: 50%;
	}

	.box_inner > .prenotations > div:nth-child(2) {
		border-left: 1px dashed #000;
	}

	.box .prenotation .prenotation_inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 20px;
		min-height: 320px;
	}

	.box .prenotation .actions {
		position: absolute;
		top: 10px;
		right: 15px;
		display: flex;
		align-items: center;
	}

	.box .prenotation .actions .edit {
		cursor: pointer;
	}

	.box .prenotation .actions .edit i {
		font-size: 16px;
	}
	
	.box .prenotation .actions .delete {
		cursor: pointer;
		margin-left: 8px;
	}

	.box .prenotation .actions .delete i {
		font-size: 16px;
	}

	.box .prenotation .actions .add {
		cursor: pointer;
		margin-left: 8px;
	}

	.box .prenotation .actions .add i {
		font-size: 18px;
	}

	.box .prenotation .cat_owner {
		font-size: 18px;
		font-weight: 700;
	}

	.box .prenotation .cat_name {
		font-size: 14px;
		font-weight: 500;
		font-style: italic;
		margin-bottom: 10px;
	}

	.box .prenotation .cat_litter {
		font-weight: 700;
		text-align: center;
		margin-bottom: 10px;
	}

	.box .prenotation .cat_litter span {
		font-size: 14px;
		font-weight: 500;
	}

	.box .prenotation .cat_playroom {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}

	.box .prenotation .cat_playroom i {
		margin-right: 5px;
	}

	.box .prenotation .cat_playroom span {
		font-size: 14px;
		font-weight: 500;
	}

	.box .prenotation .cat_notes {
		font-weight: 700;
		text-align: center;
		margin-bottom: 10px;
		max-height: 60px;
		overflow: hidden;
		cursor: pointer;
	}

	.box .prenotation .cat_notes span {
		font-size: 14px;
		font-weight: 500;
	}

	.box .prenotation .start_date {
		font-weight: 700;
	}

	.box .prenotation .start_date span {
		font-size: 14px;
		font-weight: 500;
	}

	.box .prenotation .end_date {
		font-weight: 700;
	}

	.box .prenotation .end_date span {
		font-size: 14px;
		font-weight: 500;
	}

	.box .empty_slot {
		width: 100%;
		height: 320px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.box .empty_slot .add {
		border: 2px solid #2f2f2f;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.box .empty_slot .add:hover {
		background: #2f2f2f;
	}

	.box .empty_slot .add:hover i {
		color: #fff;
	}

	.cat_change_day {
		text-align: center;
		border-top: 2px solid #000;
		padding: 10px;
		height: 78px;
		background-color: #eaeaea;
	}

	.cat_change_day.not_empty {
		background-color: #cea8ff;
	}

	.cat_change_day_row_1 {
		font-weight: 700;
	}

	.reduced_box {
		min-width: calc(10% - 10px);
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.reduced_box .reduced_box_inner {
		border: 2px solid #fff;
		border-radius: 6px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
	}
</style>