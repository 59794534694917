<template>
	<div class="settings">
		<TopHeader></TopHeader>
		<div class="container">
			<div class="settings_inner">
				<div class="settings_title">CAMBIO PASSWORD</div>
				<div class="settings_row">
					<div class="settings_col">
						<label>Password attuale</label>
						<input type="password" v-model="current_password" />
					</div>
				</div>
				<div class="settings_row">
					<div class="settings_col">
						<label>Nuova password</label>
						<input type="password" v-model="new_password" />
					</div>
					<div class="settings_col">
						<label>Ripeti password</label>
						<input type="password" v-model="new_password_repeat" />
					</div>
				</div>
				<div class="settings_row">
					<button class="button" @click="savePassword()">SALVA NUOVA PASSWORD</button>
					<i class="fas fa-spinner fa-spin settings_spinner" v-if="change_password_status == 0"></i>
					<div class="settings_success" v-else-if="change_password_status == 1">Password aggiornata con successo</div>
					<div class="settings_error" v-else-if="change_password_status == -1">{{ settings_error_text }}</div>
				</div>
				<div class="settings_title">BACKUP DATABASE</div>
				<div class="settings_row">
					<button class="button" @click="downloadBackup()">DOWNLOAD BACKUP</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
export default {
	name: 'SettingsPage',
	components: {
		TopHeader
	},
	data() {
		return {
			current_password: '',
			new_password: '',
			new_password_repeat: '',
			change_password_status: -2,
			settings_error_text: ''
		}
	},
	computed: {
		
	},
	methods: {
		savePassword: function() {
			var self = this

			if(self.current_password == '' || self.new_password == '' || self.new_password_repeat == '') {
				self.change_password_status = -1
				self.settings_error_text = 'Inserisci tutti i dati'
				return
			}

			if(self.new_password != self.new_password_repeat) {
				self.change_password_status = -1
				self.settings_error_text = 'Le password non corrispondono'
				return
			}

			self.change_password_status = 0
			self.settings_error_text = ''

			self.axios.post(
				self.$store.state.base_url + '/api/settings/changePassword',
				{
					'current_password': self.current_password,
					'new_password': self.new_password
				},
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function(response) {
				if(response.data == 1) {
					self.change_password_status = 1
				} else {
					self.change_password_status = -1
					self.settings_error_text = 'Password attuale non corretta'
				}
			})
		},
		downloadBackup: function() {
			var self = this

			self.axios.get(
				self.$store.state.base_url + '/api/settings/downloadBackup',
				{
					headers: { Authorization: `Bearer ${self.$store.state.token}` }
				}
			).then(function() {
				const url = 'https://gatthotel.netsons.org/booking_core/public/backup_db.sql';
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'backup_database.sql')
				document.body.appendChild(link)
				link.click()
			})
		}
	},
	mounted: function() {
		this.$store.dispatch('checkToken')
	}
}
</script>

<style type="text/css" scoped>
	.settings_inner {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.settings_title {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 15px;
		margin-top: 60px;
	}

	.settings_title:first-child {
		margin-top: 0px;
	}

	.settings_row {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
	}

	.settings_col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1;
		margin-right: 20px;
		max-width: 400px;
	}

	.settings_col:last-child {
		margin-right: 0px;
	}

	.settings_col label {
		font-weight: 600;
		font-size: 15px;
		margin-bottom: 5px;
	}

	.settings_col input {
		border: none;
		outline: none;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		width: 100%;
		padding: 8px 10px;
		border-radius: 3px;
	}

	.settings_spinner {
		margin-left: 20px;
	}

	.settings_error {
		font-size: 13px;
		color: red;
		font-weight: 600;
		margin-left: 20px;
	}

	.settings_success {
		font-size: 13px;
		color: green;
		font-weight: 600;
		margin-left: 20px;
	}
</style>