<template>
	<div class="header">
		<div class="container">
			<div class="header_inner">
				<div class="logo">
					<span>GATT<b>HOTEL</b>&<b>SPA</b></span>
				</div>
				<div class="menu">
					<div class="menu_item" v-bind:class="{'selected': currentRoutePath == '/daily_booking'}" @click="goTo('daily_booking')">BOOKING GIORNALIERO</div>
					<div class="menu_item" v-bind:class="{'selected': currentRoutePath == '/weekly_booking'}" @click="goTo('weekly_booking')">BOOKING SETTIMANALE</div>
					<div class="menu_item" v-bind:class="{'selected': currentRoutePath == '/owners'}" @click="goTo('owners')">PROPRIETARI</div>
					<div class="menu_item" v-bind:class="{'selected': currentRoutePath == '/veterinarians'}" @click="goTo('veterinarians')">VETERINARI</div>
					<div class="menu_item" v-bind:class="{'selected': currentRoutePath == '/settings'}" @click="goTo('settings')"><i class="fa-solid fa-gear"></i></div>
					<div class="menu_item" @click="logout()"><i class="fa-solid fa-power-off"></i></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TopHeader',
	data() {
		return {
			loading: false
		}
	},
	computed: {
		currentRoutePath() {
			return this.$route.path;
		},
	},
	methods: {
		goTo: function(page) {
			this.$router.push('/'+page);
		},
		logout: function() {
			this.$store.commit('setToken', '');
			this.$router.push('/');
		}
	}
}
</script>

<style type="text/css" scoped>
	.header {
		background: #5e72e4;
		color: #fff;
	}

	.header_inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
	}

	.logo span,
	.logo span b {
		font-size: 30px;
	}

	.menu {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.menu_item {
		margin-left: 25px;
		font-weight: 600;
		cursor: pointer;
		border-bottom: 2px solid transparent;
	}

	.menu_item.selected {
		border-bottom: 2px solid #fff;
	}
</style>